@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;300;400;500;600;700;800&display=swap');
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    text-decoration: none;
}

:root {
    --color-bg-main: #cccccc;
    --color-bg-secondary: #ffffff;
    --color-bg-card: #3a3a3a;

    --color-font-white: #ffffff;
    --color-font-black: #000000;
    --color-font-hover: #7e7b7b;
    --color-font-red: #e60a0a;

    --transition: all 400ms ease;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Roboto Condensed', sans-serif;
    background: var(--color-bg-main);
    color: var(--color-font-black);
}
